

export const IntervalType = {
  work: 'work',
  recovery: 'recovery',
  rest: 'rest'
}

export const WorkoutType = {
  interval: 'interval',
  setsAndReps: 'setsAndReps',
  timeTrial: 'timeTrial',
  race: 'race'
}



const makeId = length => {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}




const getUnit = (percentage, seconds) => {
  return {
    percentage,
    seconds
  }
}
    
    



/// let _work = Interval(.work, unit: work, set: setIdx, rep: _reps - 1)
/// let _recovery = Interval(.recovery, unit: recovery, set: setIdx, rep: _reps - 1)
/// Interval(.rest, unit: IntervalUnitModel(percentage: 0.0, seconds: rest), set: setIdx, rep: _reps - 1)


const getInterval = (rest, unit, set, rep, type) => {
  return {
    id: makeId(30),
    set,
    rep,
    percentage: unit.percentage,
    seconds: unit.seconds,
    type
  }
}


const getReps = (work, recovery, rest, reps, setIdx, isRest) => {
  
  var _reps = 0
  
  var intervals = []
  
  while (_reps < reps) {
            
      let _work = getInterval(rest, work, setIdx, _reps, IntervalType.work);
      let _recovery = getInterval(rest, recovery, setIdx, _reps, IntervalType.recovery);
      
      intervals.push(_work);
      intervals.push(_recovery);

      _reps += 1

  }
  
  
  if (isRest) {
      let _rest = getInterval(rest, { percentage: 0, seconds: rest }, setIdx, _reps, IntervalType.rest);
      intervals.push(_rest);
  }
  

  return intervals;
}



const getSets = (totalSets, reps, work, recovery, rest) => {
        
  var sets = 0;
  
  var intervals = [];

  

  while (sets < totalSets) {
      
      sets++;
      /// work, recovery, rest, reps, setIdx, isRest
      let repIntervals = getReps(work, recovery, rest, reps, sets - 1, sets < totalSets);
      

      const stuff = [
        ...intervals,
        ...repIntervals
      ];
      
      intervals = stuff;
  }
  
  return intervals
}





const getSession = (index, intervals, rest) => {
  var session = {
    id: `conditioning-${index}`,
    index,
    rest,
    intervals: {},
    type: WorkoutType.setsAndReps
  }

  if (!intervals) {
    return session;
  }

  for (let [key, value] of Object.entries(intervals)) {
    session.intervals[key] = value;
  }

  return session;
}





/// Test


const getTestSession = () => {

  let unit = getUnit(0, 360);
  let interval = getInterval(0, unit, 1, 1, IntervalType.work);

  var test = getSession();
  test.index = -1;
  test.rest = 0;
  test.id = 'conditioning-test';
  test.type = WorkoutType.timeTrial;
  test.intervals[interval.id] = interval;  
  return test;

}




/// Week One


const getSessionOne = () => {
  let intervals = getSets(2, 3, getUnit(0.85, 180), getUnit(0.3, 60), 240);
  return getSession(0, intervals, 240)
}


const getSessionTwo = () => {
  let intervals = getSets(3, 4, getUnit(1.0, 30), getUnit(0.5, 30), 240);
  return getSession(1, intervals, 180)
}

const getSessionThree = () => {
  let session = getSessionOne();
  return getSession(2, session.intervals, 240);
}



// Week Two


const getSessionFour = () => {
  let intervals = getSets(3, 6, getUnit(1.0, 30), getUnit(0.5, 30), 240);
  return getSession(3, intervals, 240);
}


const getSessionFive = () => {
  let intervals = getSets(2, 4, getUnit(0.85, 180), getUnit(0.3, 60), 240);
  return getSession(4, intervals, 240)
}

const getSessionSix = () => {
  let session = getSessionFour();
  return getSession(5, session.intervals, 240);
}




// Week Three


const getSessionSeven = () => {
  let intervals = getSets(2, 4, getUnit(0.85, 180), getUnit(0.3, 30), 240);
  return getSession(6, intervals, 240);
}


const getSessionEight = () => {
  let session = getSessionSix();
  return getSession(7, session.intervals, 240)
}

const getSessionNine = () => {
  let session = getSessionSeven();
  return getSession(8, session.intervals, 240);
}



// Week Three


const getSessionTen = () => {
  let intervals = getSets(3, 5, getUnit(1.0, 30), getUnit(0.6, 30), 240);
  return getSession(9, intervals, 180);
}


const getSessionEleven = () => {
  let intervals = getSets(2, 5, getUnit(0.9, 120), getUnit(0.4, 60), 180);
  return getSession(10, intervals, 180)
}

const getSessionTwelve = () => {
  let session = getSessionTen();
  return getSession(11, session.intervals, session.rest);
}



// Week Four




const getSessionThirteen = () => {
  let intervals = getSets(2, 6, getUnit(0.9, 120), getUnit(0.6, 120), 240);
  return getSession(12, intervals, 180);
}


const getSessionFourteen = () => {
  let intervals = getSets(3, 6, getUnit(1, 30), getUnit(0.6, 30), 180);
  return getSession(13, intervals, 180)
}

const getSessionFifteen = () => {
  let session = getSessionThirteen();
  return getSession(14, session.intervals, session.rest);
}





// Week Five




const getSessionSixteen = () => {
  let session = getSessionFourteen();
  return getSession(15, session.intervals, session.rest);
}


const getSessionSeventeen = () => {
  let session = getSessionFifteen();
  return getSession(16, session.intervals, session.rest)
}

const getSessionEighteen = () => {
  let session = getSessionFourteen();
  return getSession(17, session.intervals, session.rest);
}




export default [
  getTestSession(),
  getSessionOne(),
  getSessionTwo(),
  getSessionThree(),
  getSessionFour(),
  getSessionFive(),
  getSessionSix(),
  getSessionSeven(),
  getSessionEight(),
  getSessionNine(),
  getSessionTen(),
  getSessionEleven(),
  getSessionTwelve(),
  getSessionThirteen(),
  getSessionFourteen(),
  getSessionFifteen(),
  getSessionSixteen(),
  getSessionSeventeen(),
  getSessionEighteen()
];


