import { all, call, put, takeEvery, fork } from 'redux-saga/effects';

import { getProgram } from '../../helpers/api';

import { GET_USER_SESSIONS } from './constants';

import {
    getUserSessionsSuccess,
    getUserSessionsFailed
} from './actions';




/**
 * Register the user
 */
function* sessions({ payload: { userId } }) {

    // const options = {
    //     body: JSON.stringify({ fullname: `${firstName} ${lastName}`, email, password }),
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    // };

    try {
        const sessions = yield call(getProgram, userId);
        
        yield put(getUserSessionsSuccess(sessions));

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getUserSessionsFailed(message));
    }
}


export function* watchGetUserSessions(): any {
    yield takeEvery(GET_USER_SESSIONS, sessions);
}

function* programSaga(): any {
    yield all([fork(watchGetUserSessions)]);
}

export default programSaga;