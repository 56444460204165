import * as firebase from "firebase";







/**
 * add new athlete to club
 * @param {*} clubId
 */
const getClubAthletes = (clubId, callback) => {
  
  var db = firebase.firestore();

  return db.collection("club-members").doc(clubId)
            .collection('members')
            .get()
            .then(snapshots => {
              let athletes = snapshots.docs.map(doc => {
                return {
                  id: doc.id,
                  userId: doc.id,
                  ...doc.data()
                }
              });
              callback(Object.values(athletes));
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
       
};


/**
 * add new athlete to club
 * @param {*} clubId
 */
const getAthleteMas = (userId, callback) => {
  
  var db = firebase.firestore();

  console.log('get athlete mas tests');
  console.log(userId);
  
  return db.collection("user-mas-tests").doc(userId)
            .collection('tests')
            .get()
            .then(snapshots => {

              let masTests = snapshots.docs.map(doc => {
                return {
                  id: doc.id,
                  ...doc.data()
                }
              });
              console.log(masTests);
              callback(Object.values(masTests));
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
       
};



/**
 * add new athlete to club
 * @param {*} athlete
 * @param {*} clubId
 */
const addAthlete = (athlete, clubId) => {
  
  var db = firebase.firestore();
  console.log('save athlete to: ', clubId);
  console.log(athlete)
  return db.collection("club-members").doc(clubId)
            .collection('members').add(athlete)
            .then(ref => {
              console.log(ref);
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
       
};

/**
 * add new athlete to club
 * @param {*} athlete
 * @param {*} clubId
 */
const updateAthlete = (athlete, clubId) => {
  
  var db = firebase.firestore();

  return db.collection("club-members").doc(clubId)
            .collection('members').doc(athlete.userId).set(athlete)
            .then(ref => {
              console.log(ref);
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
       
};

/**
 * add new mas test to athlete
 * - add result to athlete history
 * - add update result in club-members
 * - load club athletes
 * @param {Object} athlete the new athlete object with updated previous times
 * @param {Object} test stuff
 * @param {String} clubId id of the club the athlete belongs
 */
const addAthleteTest = (athlete, test, clubId, callback) => {
  
  var db = firebase.firestore();
 
  return db.collection("club-members").doc(clubId)
            .collection('members').doc(athlete.userId).set(athlete)
            .then(ref => {
              return updateAthleteTestHistory(athlete, test, callback);
            })
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
       
};


/**
 * add new mas test to athlete
 * - add result to athlete history
 * @param {*} athlete
 * @param {*} test
 */
const updateAthleteTestHistory = (athlete, test, callback) => {
  var db = firebase.firestore();
  return db.collection("user-mas-tests").doc(athlete.userId)
            .collection('tests').add(test)
            .then(() => callback())
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
}

export { addAthlete, getClubAthletes, addAthleteTest, updateAthlete, getAthleteMas };