import { 
  GET_USER_SESSIONS, 
  GET_USER_SESSIONS_SUCCESS, 
  GET_USER_SESSIONS_FAILED 
} from './constants';



import sessions from '../program/sessions';


const INIT_STATE = {
    sessions: sessions,
    loading: false,
};




type ProgramAction = { type: string, payload: {} | string };
type State = { sessions?: [] | null, loading?: boolean, +value?: boolean };

const Program = (state: State = INIT_STATE, action: ProgramAction) => {
    switch (action.type) {
        case GET_USER_SESSIONS:
            return { ...state, loading: true };
        case GET_USER_SESSIONS_SUCCESS:
            const { sessions } = action.payload;
            return { ...state, sessions: sessions || [], loading: false, error: null };
        case GET_USER_SESSIONS_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Program;
