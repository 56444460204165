import { 
  GET_USER_SESSIONS, 
  GET_USER_SESSIONS_SUCCESS,
  GET_USER_SESSIONS_FAILED
  } from './constants';


type SessionAction = { type: string, payload: {} | string };





export const getUserSessions = (userId: string): SessionAction => ({
    type: GET_USER_SESSIONS,
    payload: { userId },
})


export const getUserSessionsSuccess = (sessions): SessionAction => ({
    type: GET_USER_SESSIONS_SUCCESS,
    payload: { sessions },
});

export const getUserSessionsFailed = (error: string): SessionAction => ({
    type: GET_USER_SESSIONS_FAILED,
    payload: error,
});