import {
  GET_USER_CLUBS,
  GET_USER_CLUBS_SUCCESS,
  GET_USER_CLUBS_FAILED,
  ADD_CLUB_ATHLETES,
  ADD_CLUB_ATHLETES_SUCCESS,
  ADD_CLUB_ATHLETES_FAILED
} from './constants';


type ClubAction = { type: string, payload: {} | string };

type AddAthleteAction = { type: string, payload: {} | string };





export const getUserClubs = (userId: string): ClubAction => ({
    type: GET_USER_CLUBS,
    payload: { userId },
})


export const getUserClubsSuccess = (clubs): ClubAction => ({
    type: GET_USER_CLUBS_SUCCESS,
    payload: { clubs },
});

export const getUserClubsFailed = (error: string): ClubAction => ({
    type: GET_USER_CLUBS_FAILED,
    payload: error,
});



export const addClubAthlete = (clubId: string, athlete: object): AddAthleteAction => ({
    type: ADD_CLUB_ATHLETES,
    payload: { athlete, clubId },
})


export const addClubAthleteSuccess = (clubId: string, athlete: object): AddAthleteAction => ({
    type: ADD_CLUB_ATHLETES_SUCCESS,
    payload: { athlete, clubId },
});

export const addClubAthleteFailed = (error: string): AddAthleteAction => ({
    type: ADD_CLUB_ATHLETES_FAILED,
    payload: error,
});

