import { 
  GET_USER_CLUBS, 
  GET_USER_CLUBS_SUCCESS, 
  GET_USER_CLUBS_FAILED,
  ADD_CLUB_ATHLETES,
  ADD_CLUB_ATHLETES_SUCCESS,
  ADD_CLUB_ATHLETES_FAILED
} from './constants';





const INIT_STATE = {
    clubs: [],
    isLoading: false,
};




type ProgramAction = { type: string, payload: {} | string };
type State = { clubs?: [] | null, isLoading?: boolean, +value?: boolean };

const Program = (state: State = INIT_STATE, action: ProgramAction) => {
    switch (action.type) {
        case GET_USER_CLUBS:
            return { ...state, loading: true };
        case GET_USER_CLUBS_SUCCESS:
            const { clubs } = action.payload;
            return { ...state, clubs: clubs || [], loading: false, error: null };
        case GET_USER_CLUBS_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ADD_CLUB_ATHLETES:
            return { ...state, loading: true };
        case ADD_CLUB_ATHLETES_SUCCESS:
            const { athlete, clubId } = action.payload;
            console.log(action.payload);
            return { ...state, loading: false, error: null };
        case ADD_CLUB_ATHLETES_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
};

export default Program;
