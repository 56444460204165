import { all, call, put, takeEvery, fork } from 'redux-saga/effects';

import { getClubs } from '../../helpers/api';
import { addAthlete } from '../../helpers/club-api';

import { GET_USER_CLUBS, ADD_CLUB_ATHLETES } from './constants';

import {
    getUserClubsSuccess,
    getUserClubsFailed,
    addClubAthleteSuccess,
    addClubAthleteFailed
} from './actions';




/**
 * Get the users clubs
 */
function* clubs({ payload: { userId } }) {

    // const options = {
    //     body: JSON.stringify({ fullname: `${firstName} ${lastName}`, email, password }),
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    // };

    try {
        const clubs = yield call(getClubs, userId);
        
        yield put(getUserClubsSuccess(clubs));

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(getUserClubsFailed(message));
    }
}


export function* watchGetUserClubs(): any {
    yield takeEvery(GET_USER_CLUBS, clubs);
}



/**
 * Add athlete to club
 */
function* athleteAdd({ payload: { clubId, athlete } }) {

    try {
        const newAthlete = yield call(addAthlete, athlete, clubId);
        
        console.log(newAthlete);

        yield put(addClubAthleteSuccess(clubId, newAthlete));

    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(addClubAthleteFailed(message));
    }
}


export function* watchAddClubAthelete(): any {
    yield takeEvery(ADD_CLUB_ATHLETES, athleteAdd);
}













function* clubSaga(): any {
    yield all([fork(watchGetUserClubs), fork(watchAddClubAthelete)]);
}

export default clubSaga;