
export const GET_USER_CLUBS = 'GET_USER_CLUBS';
export const GET_USER_CLUBS_SUCCESS = 'GET_USER_CLUBS_SUCCESS';
export const GET_USER_CLUBS_FAILED = 'GET_USER_CLUBS_FAILED';


export const ADD_CLUB_ATHLETES = 'ADD_CLUB_ATHLETES';
export const ADD_CLUB_ATHLETES_SUCCESS = 'ADD_CLUB_ATHLETES_SUCCESS';
export const ADD_CLUB_ATHLETES_FAILED = 'ADD_CLUB_ATHLETES_FAILED';


export const GET_CLUB_ATHLETES = 'GET_CLUB_ATHLETES';
export const GET_CLUB_ATHLETES_SUCCESS = 'GET_CLUB_ATHLETES_SUCCESS';
export const GET_CLUB_ATHLETES_FAILED = 'ADD_CLUB_ATHLETES_FAILED';