import * as firebase from "firebase";

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};


const userRequest = userId => {
  var db = firebase.firestore();
  const ref = db.collection("users").doc(userId);
  return ref.get()
            .then(doc => doc.data())
            .catch(function(error) {
                console.log("Error getting document:", error);
            });
}

const loginRequest = (username, password) => {

  return firebase.auth().signInWithEmailAndPassword(username, password)
      .then(data => {
        return data;
      })
      .catch(error => {
        // Handle Errors here.
        // var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage);
        return error;

      });
}

const registerUserRequest = (email, password) => {

  return firebase.auth()
                .createUserWithEmailAndPassword(email, password)
                .catch(error => error);

}



const saveUser = (userId, firstName, lastName) => {

  let db = firebase.firestore()
  return db.collection('users').doc(userId).set({
    userId,
    firstName,
    lastName,
    platform: 'web'
  })

}


const saveProgram = (userId, sessions) => {

  let db = firebase.firestore();
  const collection = db.collection('base-conditioning').doc(userId).collection('sessions');
  
  for (var i = 0; i < sessions.length; i++) {
    const session = sessions[i];
    collection.doc(session.id).set(session);
  }

};


const getProgram = userId => {
  
  var db = firebase.firestore();

  const ref = db.collection("base-conditioning").doc(userId)
                .collection('sessions')
                .orderBy("index");
  
  return ref.get().then(snapshots => {
    
      return snapshots.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      });
      

  }).catch(function(error) {
      console.log("Error getting document:", error);
  });
       
};


const getClubs = userId => {
  
  var db = firebase.firestore();

  const ref = db.collection("user-clubs").doc(userId)
                .collection('clubs');
  
  return ref.get().then(snapshots => {
    
      return snapshots.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      });
      

  }).catch(function(error) {
      console.log("Error getting document:", error);
  });
       
};

export { fetchJSON, loginRequest, userRequest, registerUserRequest, saveUser, saveProgram, getProgram, getClubs };
